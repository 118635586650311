import LogotypesJpg from '../../../../../public/static/logotypes.jpg';
import LogotypesWebp from '../../../../../public/static/logotypes.webp';
import styles from './logotypes.module.scss';
import SectionWrapper from './section-wrapper';

export default function Logotypes() {
  return (
    <SectionWrapper width="tight" className={styles.logotypes}>
      <picture>
        <source src={LogotypesWebp} type="image/webp"/>
        <source src={LogotypesJpg} type="image/jpg"/>
        <img src={LogotypesJpg} alt="Logotypy"/>
      </picture>
    </SectionWrapper>
  );
}