import { useAtomValue } from "jotai";
import React, { useEffect, useState } from "react";
import { atomCartWidget } from "../../atoms/atom-cart-widget";
import CartWidget from "../../components/cart-widget/cart-widget";
import MenuMobile from "../../components/navbar/menu-mobile";
import styles from "./navbar.module.scss";
import Upper from "../../components/navbar/upper";
import Lower from "../../components/navbar/lower";

export const Navbar = () => {
    const cartWidget = useAtomValue(atomCartWidget);
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        if (window.innerWidth <= 1100) {
            setIsMobile(true);
        }
    }, []);

    return (
        <>
            <nav className={styles.navbar} id="navbar">
                <Upper />
                <Lower isMobile={isMobile} />
            </nav>
            {isMobile && <MenuMobile />}
            {cartWidget && <CartWidget />}
        </>
    );
};
