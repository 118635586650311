import { Navbar } from './parialts/navbar';
import styles from './base-layout.module.scss';
import Footer from './parialts/footer';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import Logotypes from './parialts/logotypes';


export function BaseLayout({ children }: any) {
  return (
    <>
      <Navbar/>
      <main className={styles.container}>
        {children}
      </main>
      <Footer/>
      <Logotypes/>
    </>
  );
}
