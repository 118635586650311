import React from "react";
import SectionWrapper from "../../layout/parialts/section-wrapper";
import styles from "./lower.module.scss";
import Cart from "./partials/cart";
import Favourites from "./partials/favourites";
import Hamburger from "./partials/hamburger";
import Logo from "./partials/logo";
import Menu from "./partials/menu";
import Search from "./partials/search";
import classNames from "classnames";

type LowerProps = {
    isMobile: boolean;
};

const Lower = ({ isMobile = false }: LowerProps) => {
    return (
        <div className={styles.container}>
            <SectionWrapper className={styles.wrapper}>
                <div className={styles.column}>
                    <Logo />
                </div>
                <div className={styles.column}>
                    {!isMobile && <Menu />}
                    <div className={styles.options}>
                        <Search className={classNames(styles.option)} />
                        <Favourites className={styles.option} />
                        <Cart className={classNames(styles.option)} />
                        <Hamburger className={styles.option} />
                    </div>
                </div>
            </SectionWrapper>
        </div>
    );
};

export default Lower;
